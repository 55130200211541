
<template>
<div class="container-fluid mt-3">

  <spinner v-if="loading"/>
  <v-card v-else elevation="0">

    <v-card-text>
      <Checkout 
      
          v-if="data" 
          :user="user" 
          :address="data.order.address"
          :paymentProceed="paymentProceed"
          :total="data.total"
          :order="data.order"
          :client_secret="data.client_secret"
          :newOrder="false"
          :paymentUrl="paymentUrl"
          :selectedVendorPaymentOption="data.order.paymentMethod" 
          @paymentProcessing="paymentStatus"
          @validPaymentInputs="setValidPaymentInputs"
          @ordered="ordered"
        
          />
    </v-card-text>
    <v-card-actions >
          <v-btn v-if="data"
            small  
            rounded
            color="yellow lighten-3"
            @click="paymentProceed = true"
            :disabled="paymentProcessing || !validPaymentInputs"
            v-text="paymentProcessing ? $t('cart.processing') : $t('cart.place_payment')"
            >

        </v-btn>
    </v-card-actions>
  </v-card>

      <!-- Payment progress-->
      <v-dialog
      v-model="paymentProcessing"
      hide-overlay
      persistent
      width="300"
      >
      <v-card
          color="light-green lighten-2"
          dark
      >
          <v-card-title>
          {{$t('cart.proceeding_with_payment')}}
          </v-card-title>
          <v-card-text>
          {{$t('cart.please_stand_by')}}
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
      </v-dialog>

      <!-- Payment/Order Mesage-->
      <v-dialog
      v-if="orderResponse"
      v-model="orderMessageDialog"
      hide-overlay
      persistent
      width="400"
      >
      <v-card
          color="light-green lighten-1"
          dark
      >
          <v-card-title>
          {{$t('cart.payment')}}: {{paymentIntent.status}}
          </v-card-title>
          <v-card-text>
          {{paymentIntent.message}}
          </v-card-text>
          <v-card-actions>
              <v-btn small outlined text 
              @click="viewOrder()">
                 {{$t('order.view_order')}}
              </v-btn>
              <v-spacer/>
              <v-btn class="routerLink" small outlined text color="white"
              :to="{ name: 'feed' }"
              >
                 {{$t('general.go_back')}}
              </v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>


</div>
</template>


<script>

import Checkout from '../../components/Checkout.vue'
import { mapGetters } from 'vuex'

const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  middleware: ['auth','user'],

    async beforeRouteEnter (to, from, next) {
    try { 

      const { data } = await this.$http.post(`/api/orders/${to.params.id}/payment?${qs(to.query)}`)
      next( vm => {  
        vm.success = data.message,
        vm.order = data.order,
        vm.client_secret = data.client_secret,
        vm.data = data
        });

    } catch (e) {
      //console.log(e.data.message);
      next( vm => { vm.error = e.data.message })
    }
  },

  components:{
    Checkout
  },
  computed:{
        ...mapGetters({
          user: 'auth/user'
        }),
  },
   data: () => ({
     
        loading: false,
        data: null,
        order: null,
        paymentUrl: null,
        client_secret: null,
        paymentProceed: false,
        paymentProcessing: false,
        validPaymentInputs: false,
        paymentIntent: null,
        orderResponse: null,
    }),
    mounted() {
     //this.getPaymentIntent();
    },
    watch: {

    },  
    methods: {
      viewOrder(){
            this.$router.push({ name: 'order.show', params: {  id: this.order.id } });
      },  
      paymentStatus(data){
        //console.log(data);
          this.paymentProcessing = data.paymentProcessing;
          if(!this.paymentProcessing){
              this.paymentProceed = false;
          }

      },
      setValidPaymentInputs(data){
          this.validPaymentInputs = data.validPaymentInputs;
      },
      ordered(data){
        //console.log(data);
        //this.$toast.success(data.response.paymentIntent.status, "Success");
        //this.$emit('ordered', data.response.paymentIntent.status);
        this.paymentIntent = data.response.paymentIntent;
        this.orderResponse = data.response.orderResponse.order;
        this.orderMessageDialog = true;
        this.paymentProcessing = false;        
      },

    },

}
</script>







